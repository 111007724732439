import * as React from "react";
import styled from "styled-components";
import { IconContext } from "react-icons/lib";
import { GoDeviceDesktop, GoDeviceMobile, GoHome } from "react-icons/go";

const LinkIconWrapper = styled.div`
  margin-top: 0.7rem;
  margin-left: 2rem;

  @media (max-width: 768px) {
    margin-top: 0;
    margin-left: 0;
  }
`;

const LinkIcon = ({ title, url }) => {
  return (
    <LinkIconWrapper>
      <IconContext.Provider value={{ size: "2rem", color: "#61dafb" }}>
        {"携帯中継" === title && (
          <a href={url}>
            <GoDeviceMobile />
          </a>
        )}
        {"ABEMA" === title && (
          <a href={url}>
            <GoDeviceDesktop />
          </a>
        )}
        {"将棋プレミアム" === title && (
          <a href={url}>
            <GoDeviceDesktop />
          </a>
        )}
        {"中継" === title && (
          <a href={url}>
            <GoHome />
          </a>
        )}
        {"名人戦棋譜速報" === title && (
          <a href={url}>
            <GoHome />
          </a>
        )}
        {"大成建設杯清麗戦中継サイト" === title && (
          <a href={url}>
            <GoHome />
          </a>
        )}
      </IconContext.Provider>
    </LinkIconWrapper>
  );
};

export default LinkIcon;
